import { render, staticRenderFns } from "./MyClassCard.vue?vue&type=template&id=1208ce84&scoped=true"
import script from "./js/my-class-card.js?vue&type=script&lang=js&external"
export * from "./js/my-class-card.js?vue&type=script&lang=js&external"
import style0 from "./MyClassCard.vue?vue&type=style&index=0&id=1208ce84&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1208ce84",
  null
  
)

export default component.exports