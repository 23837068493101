/* eslint-disable no-useless-escape */
import { mapGetters } from 'vuex'
export default {
  name: 'biodata-card',
  props: {
    photo: { type: String },
    name: { type: String },
    joinDate: { type: String },
    email: { type: String },
    phone: { type: String },
    description: { type: String },
    selectImage: { type: Function },
    saveItem: { type: Function },
    toggleChangePassModal: { type: Function }
  },
  components: {
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    Mail: () => import(/* webpackChunkName: "icons" */ '@/components/icons/Mail'),
    Call: () => import(/* webpackChunkName: "icons" */ '@/components/icons/Call'),
    User: () => import(/* webpackChunkName: "icons" */ '@/components/icons/User'),
    Pencil: () => import(/* webpackChunkName: "icons" */ '@/components/icons/Pencil'),
    Bootcamp: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Bootcamp'),
    Lock: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Lock')
  },
  data: () => ({
    isEditImageVisible: false,
    isEditEmail: false,
    isEditPhone: false
  }),
  computed: {
    ...mapGetters('common', ['isMobile']),
    emailProp: {
      get() {
        return this.email
      },
      set(val) {
        this.$emit('handleInputEmail', val)
      }
    },
    phoneProp: {
      get() {
        return this.phone
      },
      set(val) {
        this.$emit('handleInputPhone', val)
      }
    }
  },
  methods: {
    showEditPhoto(logic) {
      this.isEditImageVisible = logic
    },
    editItem(item) {
      if (item === 'email') {
        this.isEditEmail = !this.isEditEmail
        this.$refs.email.focus()
        return
      }
      this.isEditPhone = !this.isEditPhone
      this.$refs.phone.focus()
    },
    doEdit() {
      this.$emit('edit')
    },
    outFocus(item) {
      if (item === 'email') {
        this.isEditEmail = false
        return
      }
      this.isEditPhone = false
    }
  }
}
