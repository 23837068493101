export default {
  name: 'description-card',
  components: {
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button')
  },
  props: {
    description: { type: String },
    saveItem: { type: Function }
  },
  data: () => ({
    isEditDesc: false
  }),
  watch: {
    isEditDesc(val) {
      if (val) {
        this.$refs.description.focus()
      }
    }
  },
  computed: {
    descProp: {
      get() {
        return this.description
      },
      set(val) {
        this.$emit('handleInputDescription', val)
      }
    }
  },
  methods: {
    editItem(status) {
      this.isEditDesc = status
    },

    save() {
      this.saveItem('description')
      this.isEditDesc = false
    }
  }
}
