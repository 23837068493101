export default {
  name: 'my-class-card',
  components: {
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    LinkButton: () => import(/* webpackChunkName: "button" */ '@/components/button/LinkButton'),
    Pagination: () => import(/* webpackChunkName: "Pagination" */ '@/components/pagination/Pagination'),
    TextField: () => import(/* webpackChunkName: "textfield" */ '@/components/forms/TextField'),
    Search: () => import(/* webpackChunkName: "button" */ '@/components/icons/Search'),
    ProductCard: () => import(/* webpackChunkName: "productcard" */ '@/components/card/ProductCard'),
    Enter: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Enter'),
    Calendar: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Date')
  },
  props: {
    toggleLeaveClassModal: { type: Function },
    goDashboard: { type: Function },
    myClass: { type: Array },
    paginationSize: {},
    totalClass: {}
  },
  data() {
    return {
      keyword: '',
      searchTerm: '',
      pageSize: this.paginationSize,
      totalData: this.totalClass,
      currentPage: 1
    }
  },
  methods: {
    doSearch() {
      this.searchTerm = this.keyword
      this.$emit('onSearch', this.searchTerm)
    },
    onPageChange(page) {
      this.currentPage = page
      this.$emit('pagechange', page)
    }
  }
}
