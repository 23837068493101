import { render, staticRenderFns } from "./BiodataCard.vue?vue&type=template&id=1c52b417&scoped=true"
import script from "./js/biodata-card.js?vue&type=script&lang=js&external"
export * from "./js/biodata-card.js?vue&type=script&lang=js&external"
import style0 from "./BiodataCard.vue?vue&type=style&index=0&id=1c52b417&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c52b417",
  null
  
)

export default component.exports