/* eslint-disable no-useless-escape */
import { mapActions, mapGetters } from 'vuex'
import { isNotEmptyError } from '@/utils'
const CONFIG = {
  currentPassword: 'Old Password',
  newPassword: 'New Password'
}
export default {
  name: 'change-password-modal',
  components: {
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button')
  },
  data() {
    return {
      payloads: {
        currentPassword: '',
        newPassword: ''
      },
      typeInput1: 'password',
      isTyping1: false,
      typeInput2: 'password',
      errors: [],
      isTyping2: false,
      emailAlert: null,
      isChangePasswordSuccess: null
    }
  },
  props: {
    isNewLoginFlow: {
      type: String,
      default: 'false'
    },
    email: {
      type: String
    }
  },
  watch: {
    'payloads.currentPassword': function (val) {
      val.length > 0 ? (this.isTyping1 = true) : (this.isTyping1 = false)
    },
    'payloads.newPassword': function (val) {
      val.length > 0 ? (this.isTyping2 = true) : (this.isTyping2 = false)
    }
  },
  computed: {
    ...mapGetters('prakerja', ['userProfile'])
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('auth', ['changePasswordCRM']),
    showPassword(status, passwordType) {
      if (passwordType === 'currentPass') {
        status ? (this.typeInput1 = 'text') : (this.typeInput1 = 'password')
        return
      }
      status ? (this.typeInput2 = 'text') : (this.typeInput2 = 'password')
    },
    send() {
      if (this.validForm()) {
        this.showLoading()
        const payloads = {
          email: this.userProfile.email,
          currentPassword: this.payloads.currentPassword,
          newPassword: this.payloads.newPassword
        }

        this.changePasswordCRM({
          payloads
        }).then(
          (res) => {
            if (res.data.code === 200) {
              this.isChangePasswordSuccess = true
              this.payloads.currentPassword = ''
              this.payloads.newPassword = ''
              this.emailAlert = 'Your password is successfully changed!'
              this.hideLoading()
              return
            }
            this.isChangePasswordSuccess = false
            this.emailAlert = 'Your old password is wrong!'
            this.hideLoading()
          },
          () => {
            this.isChangePasswordSuccess = false
            this.emailAlert = 'Failed to change password!'
            this.hideLoading()
          }
        )
      }
    },
    validForm() {
      const error = isNotEmptyError(this.payloads)
      this.errors = error.reduce(function (map, obj) {
        map[obj.field] = CONFIG[obj.field] + ' ' + obj.error
        return map
      }, {})
      return Object.keys(this.errors).length === 0
    },
    alertChangePassword(res) {
      this.hideLoading()
      res.code === 200 ? this.successChangePassword(res) : this.failedChangePassword(res)
    },

    successChangePassword() {
      this.isChangePasswordSuccess = true
      this.email = ''
      this.payloads.currentPassword = ''
      this.payloads.newPassword = ''
      this.emailAlert = 'Password anda berhasil diganti'
    },

    failedChangePassword(res) {
      if (res.errors.error[0] === 'INVALID_CREDENTIALS email or password not match') {
        return
      }
      this.isChangePasswordSuccess = false
      this.emailAlert = 'Password lama anda salah!'
    },
    errorChangePassword() {
      this.hideLoading()
      this.isChangePasswordSuccess = false
    },
    close() {
      this.payloads.currentPassword = ''
      this.payloads.newPassword = ''
      this.$emit('close')
    }
  }
}
